.container-rate-progress {
  display: flex;
}

.arrow-container-rate-progress {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.vertical-line-rate-progress {
  width: 8px;
  height: 300px;
  background-color: #484848;
}

.triangel-up-rate-progress {
  margin-top: 30px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #484848;
}

.horizontal-right-line-rate-progress {
  width: 50px;
  border: solid 1px #fadd00;
  margin-left: -14px;
}

.horizontal-left-line-rate-progress {
  width: 50px;
  border: solid 1px #ff6500;
  margin-right: -14px;
  z-index: 0;
}

.triangle-left-rate-progress {
  border-top: 5px solid transparent;
  border-right: 10px solid #fadd00;
  border-bottom: 5px solid transparent;
  margin-left: -2px;
}
.triangle-right-rate-progress {
  border-top: 5px solid transparent;
  border-left: 10px solid #ff6500;
  border-bottom: 5px solid transparent;
  margin-right: -2px;
}

.right-value-container {
  margin-bottom: -3px;
  font-size: 0.875rem;
  font-weight: bold;
}

.left-value-container {
  margin-bottom: -3px;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: end;
}

.right-text-container {
  font-size: 0.875rem;
  margin-left: 6px;
}

.left-text-container {
  font-size: 0.875rem;
  margin-right: 6px;
}

.absolute-position {
  position: absolute;
}
.margin-left-rate-progress {
  margin-left: -140px;
}

.margin-left-rate-progress.required-rate {
  margin-left: -156px;
}
