.tab-container {
  display: flex;
  align-items: center;
  padding: 32px 2rem 18px;
}

.tabs {
  display: flex;
}

.project-name {
  font-size: 1.125rem;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
}

.link {
  text-decoration: none;
}

.tab-text {
  font-size: 0.85rem;
  color: #2e3e46;
}

.button {
  width: 90px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: solid 1px #2e3e46;
}

/* .overview {
  border-radius: 4px 0 0 4px;
} */

.weekly {
  /* border-radius: 0 4px 4px 0; */
  margin-left: -1px;
};